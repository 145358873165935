<template>
  <div class="Edit_meeting finish-meeting show_meeting">
    <div
      class="pages_header member-tablet-view d-flex align-items-center justify-content-between"
    >
      <h3 class="page_title">تم الإنتهاء من الاجتماع</h3>
      <div class="btns">
        <a class="btn_Green btn_main" @click="$router.push('/new_meeting')">
          <i class="fas fa-plus"></i>
          <span> إجتماع جديد </span>
        </a>
      </div>
    </div>

    <div class="form_container meeting-link">
      <div class="title_finsh text-center">تم الإنتهاء من الاجتماع</div>

      <div class="form_body mt-9">
        <div class="metting_info row">
          <ul class="header_list ml-0 col-3">
            <li>
              <span>عنوان الاجتماع</span>
              <span></span>
            </li>
            <!-- <li>
              <span>الغرض من الاجتماع</span>
              <span></span>
            </li> -->
          </ul>
          <ul class="info_list col-9">
            <li>
              <span>{{ meeting.title }}</span>
            </li>
            <!-- <li>
              <span>اجتماع لتحسين الموقع الحالي</span>
            </li> -->
          </ul>
        </div>

        <!-- <form class="row">
          <div class="form-group col-md-12 d-block">
            <label class="item_label">
              <span>ربط الاجتماع بمبادرة سابقة</span>
              <span
                style="font-size: 15px; font-weight: normal; margin-right: 10px"
                >(اختياري)</span
              >
            </label>
            <input
              class="input_title"
              value="البحث باسم الاجتماع"
              type="text"
              placeholder="اسم المجموعة"
              id="group_name"
            />
          </div>
          <div class="form-group col-md-12 d-block">
            <label class="item_label"> طبيعة الاجتماع </label>
            <v-radio-group mandatory class="row">
              <div class="col-md-4 col-12">
                <v-radio
                  id="inside-meeting"
                  label="إجتماع داخلي"
                  value="radio-1"
                ></v-radio>
              </div>
              <div class="col-md-4 col-12">
                <v-radio
                  id="client-meeting"
                  label="إجتماع مع عميل"
                  value="radio-2"
                ></v-radio>
              </div>
            </v-radio-group>
          </div>
          <div class="form-group col-md-12 d-block">
            <label class="item_label">درجه الأهمية</label>
            <v-radio-group mandatory class="row">
              <div class="col-md-4 col-12">
                <v-radio id="less" label="أقل أهمية" value="radio-1"></v-radio>
              </div>
              <div class="col-md-4 col-12">
                <v-radio id="more" label="مهم" value="radio-2"></v-radio>
              </div>
              <div class="col-md-4 col-12">
                <v-radio
                  id="extremly"
                  label="شديد الأهمية"
                  value="radio-3"
                ></v-radio>
              </div>
            </v-radio-group>
          </div>
          <div class="form-group col-md-12 d-block">
            <label class="item_label">
              <span>ربط الاجتماع ب task</span>
              <span
                style="font-size: 15px; font-weight: normal; margin-right: 10px"
                >(اختياري)</span
              >
            </label>
            <input
              class="input_title"
              value="البحث باسم الاجتماع"
              type="text"
              placeholder="اسم المجموعة"
              id="group_name"
            />
          </div>
        </form> -->
        <div class="meeting-input mt-16">
          <div class="title">
            <span>مواضيع الاجتماع</span>
          </div>
          <div class="topics_list" v-if="meeting_topics.length">
            <!-- completed -->
            <div
              v-for="item in meeting_topics"
              :key="item.id"
              class="step"
              :class="item.checked ? 'completed' : ''"
            >
              <div class="v-stepper">
                <div class="circle" @click="markTopic(item.id)">
                  <i class="fa fa-check"></i>
                </div>
                <div class="line"></div>
              </div>
              <!-- <div
                      v-if="Edit_Topick && edited_topick == index"
                      class="w-100 input-search position-relative"
                    >
                      <input
                        ref="editedTopic"
                        v-model="EditedTopic"
                        class="form-control"
                        type="search"
                        name="meeting-link"
                        id="editedTopic"
                        placeholder="موضوع الاجتماع"
                      />
                      <button
                        type="button"
                        @click="EditTopickinPlace(index)"
                        class="add_input_icon"
                      >
                        <img src="/media/svg/edit_w.svg" />
                      </button>
                    </div> -->
              <div class="content">
                {{ item.topic }}
                <div class="list_actions">
                  <!--                        <button-->
                  <!--                          type="button"-->
                  <!--                          @click="EditTopick(item, index)"-->
                  <!--                          class="p-0 mr-2 text-center"-->
                  <!--                        >-->
                  <!--                          <img-->
                  <!--                            style="width: 12px"-->
                  <!--                            src="/media/svg/edit_metings.svg"-->
                  <!--                          />-->
                  <!--                        </button>-->
                  <!--                        <button-->
                  <!--                          type="button"-->
                  <!--                          @click="delete_meeting_topic(item.id)"-->
                  <!--                          class="p-0 text-center"-->
                  <!--                        >-->
                  <!--                          <img-->
                  <!--                            style="width: 12px"-->
                  <!--                            src="/media/svg/delete.svg"-->
                  <!--                          />-->
                  <!--                        </button>-->
                </div>
              </div>
            </div>
          </div>
          <div v-else>لايوجد</div>
        </div>
        <!-- <div class="invited_usesr">
          <div class="meeting-input mt-16">
            <div class="title">
              <span>الحضور</span>
            </div>
          </div>
          <draggable class="row" :list="list1" group="people" @change="log">
            <div class="col-md-4" v-for="element in list1" :key="element.name">
              <div class="user_data">
                <img :src="element.img" />
                <span class="user_name">{{ element.title }}</span>
              </div>
            </div>
          </draggable>
        </div> -->
        <div class="meeting-input mt-16">
          <div class="title">
            <span>المهام المطلوبة</span>
          </div>
          <!-- <div class="d-flex mb-5">
            <div class="col-md-3 to_drop_in">
              <draggable class="list-group" :list="list2" group="people">
                <div v-for="element in list2" :key="element.name">
                  <div class="user_data">
                    <img :src="element.img" />
                    <span class="user_name">{{ element.title }}</span>
                  </div>
                </div>
              </draggable>
            </div>
            <div class="input-search position-relative col-md-9">
              <input
                style="height: 60px"
                class="form-control"
                name="meeting-link"
                id=""
                placeholder="المهمة المطلوب تنفيذها"
              />
              <button class="add_input_icon">
                <img src="/media/svg/plus.svg" />
              </button>
            </div>
          </div> -->
          <div>
            <!-- <div class="d-flex mb-2" style="min-height: 40px"> -->
            <!-- <div class="col-md-4 to_drop_in">
                    <draggable
                      class="list-group p-2"
                      :list="TaskList"
                      :group="{ name: 'people', pull: false, put: true }"
                    >
                      <template v-if="TaskList.length">
                        <div
                          v-for="element in TaskList"
                          :key="element.name"
                          class="listItem"
                        >
                          <div class="user_data">
                            <img
                              :src="element.img ? element.img : element.avatar"
                            />
                            <span class="user_name">{{ element.name }}</span>
                          </div>
                        </div>
                      </template>
                      <div
                        style="height: 15px; font-size: 8px"
                        class="selectPlaceholder"
                        v-else
                      >
                        إضافة شخص
                      </div>
                    </draggable>
                  </div>
                  <div class="input-search tasks_input position-relative w-100">
                    <input
                      v-model="taskText"
                      type="text"
                      name="meeting-link"
                      placeholder="المهمة المطلوب تنفيذها"
                    />

                    <button v-if="loadingaddTask" class="add_input_icon">
                      <div class="loader_main"></div>
                    </button>
                    <button v-else @click="btnAddTask" class="add_input_icon">
                      <img src="/media/svg/plus.svg" />
                    </button>
                  </div>
                </div> -->
            <div class="tasks" v-if="MeetingTasks.length">
              <ul>
                <li v-for="(task, index) in MeetingTasks" :key="task.id">
                  <div
                    v-if="Edit_Tasks && edited_task == index"
                    class="d-block bg_gray p_list w-100"
                  >
                    <div class="d-flex">
                      <b-dropdown
                        position="is-bottom-left"
                        class="dropdownList"
                        aria-role="list"
                      >
                        <template #trigger>
                          <a
                            class="navbar-item p-0"
                            role="button"
                            v-if="task.users && task.users.length"
                          >
                            <div v-if="task.users.length" class="user_data m-0">
                              <img :src="task.users[0].avatar" />
                              <span class="user_name ml-2">
                                {{ task.users[0].name }}
                              </span>
                              <b-icon icon="menu-down mr-auto"></b-icon>
                            </div>
                            <div v-else class="user_data m-0">
                              <img :src="task.groups[0].image" />
                              <span class="user_name ml-2">
                                {{ task.groups[0].name }}
                                <!-- <truncate
                                      clamp=""
                                      :length="3"
                                      less="Show Less"
                                      :text=""
                                    ></truncate> -->
                              </span>
                              <b-icon icon="menu-down mr-auto"></b-icon>
                            </div>
                          </a>
                        </template>
                        <b-dropdown-item
                          paddingless
                          aria-role="listitem"
                          v-for="item in task.users"
                          :key="item.id"
                        >
                          <div class="user_data mb-2">
                            <img :src="item.avatar" />
                            <span class="user_name">{{ item.name }}</span>
                          </div>
                        </b-dropdown-item>
                        <b-dropdown-item
                          paddingless
                          aria-role="listitem"
                          v-for="item in task.groups"
                          :key="item.id"
                        >
                          <div class="user_data mb-2">
                            <img :src="item.image" />
                            <span class="user_name">{{ item.name }}</span>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>
                      <div class="d-flex w-100 position_relative">
                        <h6>
                          {{ task.required }}
                        </h6>
                        <div class="list_actions">
                          <button
                            type="button"
                            @click="EditTasks(task, index)"
                            class="p-0 mr-2 text-center"
                          >
                            <img
                              style="width: 17px"
                              src="/media/svg/edit_metings.svg"
                            />
                          </button>
                          <button
                            v-if="loadingDeleteTask && index == deletedTask"
                            class="p-0 text-center"
                          >
                            <div class="loader_delete"></div>
                          </button>

                          <button
                            v-else
                            @click="delete_meeting_task(task.id, index)"
                            class="p-0 text-center"
                          >
                            <img
                              style="width: 17px"
                              src="/media/svg/delete.svg"
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="mt-5 position_relative">
                      <input
                        ref="editedTopic"
                        v-model="EditedTask.required"
                        class="form-control"
                        type="search"
                        name="meeting-link"
                        id="editedTopic"
                      />
                      <div class="text-right mt-2">
                        <!--  -->
                        <button
                          v-if="loadingTaskEdited"
                          type="button"
                          class="btn_Green btn_main_edit"
                        >
                          <div class="loader_edit"></div>
                        </button>
                        <button
                          v-else
                          type="button"
                          @click="update_meeting_tasks(index)"
                          class="btn_Green btn_main_edit"
                        >
                          تعديل
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="d-flex w-100 position_relative">
                    <b-dropdown
                      position="is-bottom-left"
                      class="dropdownList"
                      aria-role="list"
                    >
                      <template #trigger>
                        <a
                          class="navbar-item p-0"
                          role="button"
                          v-if="task.users && task.users.length"
                        >
                          <div
                            v-if="task.users.length"
                            class="user_data bg_gray m-0"
                          >
                            <img :src="task.users[0].avatar" />
                            <span class="user_name ml-2">
                              {{ task.users[0].name }}
                              <!-- <truncate
                                      clamp=""
                                      :length="3"
                                      less="Show Less"
                                      :text="task.users[0].name"
                                    ></truncate -->
                            </span>
                            <b-icon icon="menu-down mr-auto"></b-icon>
                          </div>
                          <div v-else class="user_data bg_gray m-0">
                            <img :src="task.groups[0].image" />
                            <span class="user_name ml-2">
                              {{ task.groups[0].name }}
                              <!-- <truncate
                                      clamp=""
                                      :length="3"
                                      less="Show Less"
                                      :text="task.groups[0].name"
                                    ></truncate -->
                            </span>

                            <b-icon icon="menu-down mr-auto"></b-icon>
                          </div>
                        </a>
                      </template>
                      <b-dropdown-item
                        paddingless
                        aria-role="listitem"
                        v-for="item in task.users"
                        :key="item.id"
                      >
                        <div class="user_data mb-2">
                          <img :src="item.avatar" />
                          <span class="user_name">{{ item.name }}</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        paddingless
                        aria-role="listitem"
                        v-for="item in task.groups"
                        :key="item.id"
                      >
                        <div class="user_data mb-2">
                          <img :src="item.image" />
                          <span class="user_name">{{ item.name }}</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                    <div
                      class="d-flex w-100 topic_list p_list position_relative"
                    >
                      <h6>
                        {{ task.required }}
                      </h6>
                      <div class="list_actions">
                        <button
                          type="button"
                          @click="EditTasks(task, index)"
                          class="p-0 mr-2 text-center"
                        >
                          <img
                            style="width: 17px"
                            src="/media/svg/edit_metings.svg"
                          />
                        </button>
                        <button
                          v-if="loadingDeleteTask && index == deletedTask"
                          class="p-0 text-center"
                        >
                          <div class="loader_delete"></div>
                        </button>

                        <button
                          v-else
                          @click="delete_meeting_task(task.id, index)"
                          class="p-0 text-center"
                        >
                          <img
                            style="width: 17px"
                            src="/media/svg/delete.svg"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else>لايوجد</div>
          </div>
        </div>

        <div class="metting_notes">
          <div class="meeting-input mt-16">
            <div class="title">
              <span>محضر الإجتماع</span>
            </div>
          </div>
          <div class="text_editor">
            <wysiwyg placeholder="" v-model="meeting_Note" />
          </div>
        </div>

        <div class="invited_usesr w-100">
          <div class="row">
            <div
              class="col-md-6"
              v-for="element in meeting.users"
              :key="element.name"
            >
              <div class="d-flex">
                <vs-checkbox
                  @change="userToSendNote($event.target, element.id)"
                  style="height: 45px"
                ></vs-checkbox>
                <div class="user_data" style="width: 320px">
                  <img :src="element.avatar" />
                  <span class="user_name">{{ element.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-12 form_footer">
          <div class="d-flex justify-content-end">
            <button
              class="btn_Green btn_main"
              @click="update_meeting_Note()"
            >
              <span>حفظ وإرسال</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import meetings from "@/http/requests/meetings";
import users from "@/http/requests/users";
export default {
  name: "MeetingFinshed",
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 2);
    maxDate.setDate(15);
    return {
      meeting: {},
      meeting_topics: [],
      TaskList: [],
      MeetingTasks: [],
      taskText: [],
      loadingaddTask: false,
      loadingDeleteTask: false,

      deletedTask: null,
      Edit_Tasks: false,
      edited_task: "",
      EditedTask: {},
      loadingTaskEdited: false,
      meeting_Note: "",
NoteUsers:[],
      value1: "",
      value2: "",
      value3: "",
      min: minDate,
      max: maxDate,
      myHTML: undefined,
      switch2: undefined,
      metting_time: "",
      Search_metting_value: undefined,
      value: undefined,
      e1: 1,
      list1: [
        {
          title: "محمد على ",
          img: "/media/svg/1.svg",
        },
        {
          title: "خسين محمود ",
          img: "/media/svg/3.svg",
        },
        {
          title: "سيد محمد ",
          img: "/media/svg/3.svg",
        },
        {
          title: "شادى ابراهيم ",
          img: "/media/svg/2.svg",
        },
        {
          title: "خسين عادل ",
          img: "/media/svg/3.svg",
        },
        {
          title: "سيد على ",
          img: "/media/svg/4.svg",
        },
        {
          title: "ندى ابراهيم ",
          img: "/media/svg/1.svg",
        },
      ],
      list2: [],
    };
  },
  // components: {
  //   draggable,
  // },
  methods: {
    getMeetingTopics() {
      meetings.getMeetingTopics(this.meeting.id).then((res) => {
        this.meeting_topics = res.data;
      });
    },
    EditTasks(task, index) {
      this.Edit_Tasks = true;
      this.edited_task = index;
      this.EditedTask = task;
    },
    update_meeting_tasks() {
      this.loadingTaskEdited = true;
      const form_section3 = {
        id: this.EditedTask.id,
        required: this.EditedTask.required,
        users: this.EditedTask.users.map((item) => item.id),
        groups: this.EditedTask.groups.map((item) => item.id),
      };
      meetings
        .update_single_task(form_section3)
        .then(() => {
          this.loadingTaskEdited = false;
          this.Edit_Tasks = false;
          this.getMeetingTasks();
          this.$vs.notify({
            text: "تم تعديل  مهام الإجتماع بنجاح",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    delete_meeting_task(id, index) {
      this.deletedTask = index;
      this.loadingDeleteTask = true;
      meetings
        .delete_meeting_task(id)
        .then((res) => {
          this.getMeetingTasks();
          this.$vs.notify({
            text: "تم حذف المهمه بنجاح",
            color: "success",
          });
          this.loadingDeleteTask = false;
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    markTopic(topic_id) {
      meetings.markMeetingTopic(this.meeting.id, topic_id).then();
    },
    getMeeting() {
      this.$vs.loading();
      this.meeting = {};

      meetings.getSingleMeeting(this.$route.params.slug).then((res) => {
        this.meeting = res.data.data;
        if (res.data.data.note) {
          this.meeting_Note = res.data.data.note.note;
        }
        this.getMeetingTopics();
        this.getMeetingTasks();
        // this.getMeetingUsers();
        // this.getMeetingAttachments();
        this.$vs.loading.close();
      });
    },
    getMeetingTasks() {
      (this.MeetingTasks = []),
        meetings.list_meeting_task(this.$route.params.slug).then((res) => {
          this.MeetingTasks = res.data.data;
        });
    },
    btnAddTask() {
      this.loadingaddTask = true;
      this.taskUsers = [];
      this.taskGroups = [];
      for (let i = 0; i < this.TaskList.length; i++) {
        if (this.TaskList[i].email) {
          this.taskUsers.push(this.TaskList[i]);
        } else {
          this.taskGroups.push(this.TaskList[i]);
        }
      }
      if (this.taskUsers.length || this.taskGroups.length) {
        this.taskNew = {
          required: this.taskText,
          users: this.taskUsers.map((item) => item.id),
          groups: this.taskGroups.map((item) => item.id),
          meeting_id: this.meeting.id,
        };
      } else {
        this.$vs.notify({
          text: "برجاء اختيار عضو او مجموعة",
          color: "danger",
        });
      }
      this.TaskList = [];
      this.taskText = "";
      meetings
        .single_task_store(this.taskNew)
        .then(() => {
          this.loadingaddTask = false;
          this.Edit_Topick = false;
          this.getMeetingTasks();
          this.$vs.notify({
            text: "تم إضافة المهمة للإجتماع بنجاح",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response.data.error) {
            this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
          } else {
            this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
          }
        });
    },
    userToSendNote(event, user_id) {
      if (event.checked) {
        this.NoteUsers.push(user_id);
      } else {
        for (let i = 0; i <= this.NoteUsers.length; i++) {
          if (this.NoteUsers[i] == user_id) {
            this.NoteUsers.splice(i, 1);
          }
        }
      }
    },
    update_meeting_Note() {
      this.$vs.loading();
      if (this.meeting.note) {
        const form_note = {
          note: this.meeting_Note,
          users: this.NoteUsers,
          meeting_id: this.meeting.id,
        };
        meetings
          .update_note_meeting(this.meeting.note.id, form_note)
          .then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              text: "تم إرسال ملخص الإجتماع بنجاح",
              color: "success",
            });
          })
          .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      } else {
        const data = {
          note: this.meeting_Note,
          meeting_id: this.meeting.id,
          users: this.NoteUsers,
        };

        meetings
          .CreateMeetingNote(data)
          .then((res) => {
            this.$vs.notify({
              text: "تم إرسال الملخص بنجاح",
              color: "success",
            });

            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "برجاء إدخال ملخص الإجتماع",
                color: "danger",
              });
            }
          });
      }
      this.$router.push(`/meetings_list`)
    },

    add: function () {
      this.list.push({ name: "Juan" });
    },
    replace: function () {
      this.list = [{ name: "Edgard" }];
    },
    clone: function (el) {
      return {
        name: el.name + " cloned",
      };
    },
    log: function (evt) {
      window.console.log(evt);
    },
  },
  created() {
    this.getMeeting();
  },
};
</script>
<style scoped>
.show_meeting .form_container {
  padding: 70px;
}
.list_actions {
  top: 25px;
  left: 15px;
}
.p_list {
  padding: 10px 20px;
}
</style>

