import axios from '../axios/index.js'
export default {
    getusers(page = 1,search) {
        var keyword=''
        if(search){
           keyword= '&keyword='+search
        }
        else{
            keyword= ''
        }
        return axios.get(`/users?page=${page}`+ keyword)
    },
    add_users_group(group_id,search) {
        var keyword=''
        if(search){
           keyword= '&keyword='+search
        }
        else{
            keyword= ''
        }
        return axios.get(`/users_group/?group_id=${group_id}$page=1`+ keyword)
    },
    getSingleusers(id){
        return axios.get(`/users/${id}`)
    },
    getuserRoles() {
        return axios.get(`/roles`)
    },
    getuserData() {
        return axios.get(`/user_data`)
    },
    UpdateUser(id, data) {
        return axios.post(`/users/${id}`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    UpdateProfile(data) {
        return axios.post(`/update_profile`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    CreateUsers(data) {
        return axios.post(`/users`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    deleteSingleUser(id) {
        return axios.delete(`/users/${id}`)
    },
    change_password(data){
        return axios.post(`/change_password`,data)
    }
    
    
}