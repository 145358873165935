import axios from '../axios/index.js'
export default {
    getMeetings(page = 1, search) {
        var keyword = ''
        if (search) {
            keyword = '&keyword=' + search
        }
        else {
            keyword = ''
        }
        return axios.get(`/meetings?page=${page}&limit=12` + keyword)
    },
    getMeetingsFilters(page = 1, filter) {
        return axios.get(`/meetings?page=${page}&limit=12&status=${filter}`)
    },
    getSingleMeeting(id) {
        return axios.get(`/meetings/${id}`)
    },
    searchMembers(data) {
        return axios.post(`/search_members`, data)
    },

    CreateMeeting(data) {
        return axios.post(`/meetings`, data)
    },
    CreateMeetingNote(data) {
        return axios.post(`/create_note_meeting`, data)
    },
    EditMeeting(id,data) {
        return axios.put(`/meetings/${id}`, data)
    },
    update_note_meeting(id,data) {
        return axios.put(`/update_note_meeting/${id}`, data)
    },
    EditMeetingMembers(data) {
        return axios.put(`/update_meeting_members`, data)
    },
    update_meeting_tasks(data) {
        return axios.put(`/update_meeting_tasks`, data)
    },
    update_meeting_topics(data) {
        return axios.put(`/update_meeting_topics`, data)
    },
    CreateMeetingStep2(data) {
        return axios.post(`/store_step2`, data)
    },
    delete_meeting_topic(id) {
        return axios.delete(`/delete_meeting_topic/${id}`)
    },
    delete_meeting_task(id) {
        return axios.delete(`/delete_meeting_task/${id}`)
    },
    admin_start_meeting(id) {
        return axios.put(`/admin_start_meeting/${id}`)
    },
    user_join_meeting(data){

        return axios.post(`/user_join_meeting`,data)
    },
    user_left_meeting(data){

        return axios.put(`/user_left_meeting`,data)
    },
    notify_meeting_users(data){
        return axios.post(`/notify_meeting_users`, data)
    },
    single_task_store(task){
        return axios.post(`/single_task_store`, task)
    },
    list_meeting_task(slug){
        return axios.get(`/list_meeting_tasks/${slug}`)
    },
    update_single_task(data){
        return axios.put(`/update_single_task`,data)
    },
    getMeetingUsers(meeting){
        return axios.get(`/meetings/${meeting}/users`)
    },
    getMeetingTopics(meeting){
        return axios.get(`/meetings/${meeting}/topics`)
    },
    markMeetingTopic(meeting,topic){
        return axios.put(`/meetings/${meeting}/topics/${topic}`)
    },
    getMeetingAttachments(meeting){
        return axios.get(`/meetings/${meeting}/attachments`)
    },
    uploadAttachment(meeting, data) {
        return axios.post(`/meetings/${meeting}/attachments`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

}
