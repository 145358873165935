// axios
import axios from 'axios'
import JwtService from "@/core/services/jwt.service";
const baseURL = process.env.VUE_APP_API_BASE_URL ||  "https://emeetingapi.tstme.online/api";

// const baseURL =  "https://emeetingapi.tstme.online/api";
// const baseURL = process.env.NODE_ENV === "development" ?
  // "https://emeetingdev.tstme.online/api" : "https://emeetingapi.tstme.online/api";
export default axios.create({
  baseURL,
  // You can add your headers here
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${JwtService.getToken()||window.localStorage.getItem("gust_token")}`
  }
})
